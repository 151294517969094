import { emit as zapierEmit } from "@zapier/events-toolkit-browser";
import { isEmpty } from "lodash";
import { trpcClient } from "utils/trpc";

export async function emit(
  eventData: Record<string, any>,
  eventType: string
): Promise<[boolean, Record<string, any> | undefined]> {
  const emitResponse = await zapierEmit(eventData, eventType);

  if (!isEmpty(emitResponse) && !emitResponse[0]) {
    await trpcClient.internal.logAvroEmitFailure.mutate({
      eventData,
      eventType,
    });
  }

  return emitResponse;
}

/**
 * These paths are served from zapier.com, proxied through cloudfront.
 *
 * We should not expect interfaces session or other interfaces-specific cookies to work
 * on these paths, so this utility can detect if it is one of those pages and
 * adjust behavior accordingly.
 */
// TODO (NPGRW-66): Remove /public after cloudfront config has the "public" origin_path removed
export const pathsServedFromZapierCore = ["/public", "/templates", "/forms"];

export const isPathServedFromZapierCore = (pathname: string) => {
  return pathsServedFromZapierCore.some((p) => pathname.startsWith(p));
};

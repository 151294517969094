/**
 * These have to check on process.env directly, as opposed to the config
 * package, since they are used by the config package.
 */
export const isProduction = process.env.NEXT_PUBLIC_APP_ENV === "production";
export const isStaging = process.env.NEXT_PUBLIC_APP_ENV === "staging";
export const isSandbox = process.env.NEXT_PUBLIC_APP_ENV === "sandbox";
export const isLocal = process.env.NEXT_PUBLIC_APP_ENV === "local";
export const isTest = process.env.NODE_ENV === "test";

export const getEnvironment = () => {
  if (isProduction) {
    return "production";
  }

  if (isStaging) {
    return "staging";
  }

  if (isSandbox) {
    return "sandbox";
  }

  if (isLocal) {
    return "local";
  }

  if (isTest) {
    return "test";
  }

  return undefined;
};

import { emit } from "@zapier/events-toolkit-browser";
import { SYSTEM_NAME, TABLES_USAGE_LIMIT_EXCEEDED_EVENT } from "./events";
import {
  PlatformTablesManagementTableUsageLimitExceedAttemptEvent,
  RemoveAutoPopulatedEventFields,
} from "@zapier/avro-events";

type RemoveConstants<T> = Omit<
  RemoveAutoPopulatedEventFields<T>,
  | "system_name"
  | "customuser_id"
  | "account_id"
  | "timestamp_ms"
  | "visitor_id"
  | "session_id"
>;

export type TableUsageLimitExceedAttemptPayload =
  RemoveConstants<PlatformTablesManagementTableUsageLimitExceedAttemptEvent.EventFields>;

export function emitTablesUsageLimitsEvent(
  payload: Partial<TableUsageLimitExceedAttemptPayload>,
  customUserId: number | null,
  accountId: number | null
) {
  emit(
    {
      ...payload,
      system_name: SYSTEM_NAME,
      customuser_id: customUserId,
      account_id: accountId,
      attempt_method_name: "Interfaces",
    },
    TABLES_USAGE_LIMIT_EXCEEDED_EVENT
  );
}

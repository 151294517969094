export const SYSTEM_NAME = "interfaces";
export const PAGE_VIEW_EVENT = "web_analytics.tracking.page_view.PageViewEvent";
export const USER_INTERACTION_EVENT =
  "web_analytics.tracking.user_interaction.UserInteractionEvent";
export const BUILDER_INTERACTION_EVENT =
  "platform.interfaces.web_analytics.InterfaceBuilderInteractionEvent";
export const CONSUMER_INTERACTION_EVENT =
  "platform.interfaces.web_analytics.InterfaceConsumerInteractionEvent";
export const CHATBOTS_CONSUMER_INTERACTION_EVENT =
  "platform.chatbots.web_analytics.ChatbotsConsumerInteractionEvent";
export const CHATBOT_INTERACTION_EVENT =
  "platform.interfaces.web_analytics.InterfaceChatbotAITokenUsageReceivedEvent";
export const TABLES_PLAN_FEATURE_ACCESS_ATTEMPT_EVENT =
  "platform.tables.management.TablePlanFeatureAccessAttemptEvent";
export const TABLES_USAGE_LIMIT_EXCEEDED_EVENT =
  "platform.tables.management.TableUsageLimitExceedAttemptEvent";

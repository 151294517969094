import * as styledComponents from "styled-components";
import { Theme } from "./themes";

const {
  default: styled,
  css,
  createGlobalStyle,
  useTheme,
  keyframes,
} = styledComponents as styledComponents.ThemedStyledComponentsModule<Theme>;

export { styled, css, createGlobalStyle, useTheme, keyframes };

import { datadogRum } from "@datadog/browser-rum";
import { Serialize } from "@trpc/server/unstable-core-do-not-import";
import { TRPCClientError } from "@trpc/client";
import React, {
  ReactElement,
  useState,
  useEffect,
  useContext,
  createContext,
  useMemo,
  useCallback,
} from "react";
import { UserProfile } from "server/schemas/user";
import { isPathServedFromZapierCore } from "utils/isPathServedFromZapierCore";
import { trpc } from "utils/trpc";
import { isPublishedPageHost } from "utils/isPublishedPageHost";

export type ClientUserProfile = Serialize<UserProfile>;

export type UserContext = {
  user?: ClientUserProfile;
  error?: Error;
  isLoading: boolean;
  syncSession: () => Promise<void>;
};

export type UserProviderProps = React.PropsWithChildren<{
  fallback?: React.ReactNode;
  user?: ClientUserProfile;
}>;

const missingUserProvider = "You forgot to wrap your app in <UserProvider>";

export const UserContext = createContext<UserContext>({
  get user(): never {
    throw new Error(missingUserProvider);
  },
  get error(): never {
    throw new Error(missingUserProvider);
  },
  get isLoading(): never {
    throw new Error(missingUserProvider);
  },
  syncSession: (): never => {
    throw new Error(missingUserProvider);
  },
});

export type UseUser = () => UserContext;

export const useUser: UseUser = () => useContext<UserContext>(UserContext);

export type UserProvider = (
  props: UserProviderProps
) => ReactElement<UserContext>;

type UserProviderState = {
  user?: ClientUserProfile;
  error?: Error;
  isLoading: boolean;
};

export default function UserProvider({
  children,
  fallback,
  user: initialUser,
}: UserProviderProps): ReactElement<UserContext> {
  const [isNotEditorPage, setIsNotEditorPage] = useState<boolean>(true);

  const [state, setState] = useState<UserProviderState>({
    user: initialUser,
    isLoading: !initialUser,
  });

  const shouldSkipUserFetching = useMemo(
    () => isNotEditorPage || state.user,
    [isNotEditorPage, state.user]
  );

  useEffect(() => {
    if (typeof window === "undefined") return;

    const isPublishedPage = isPublishedPageHost(window.location.host);
    const isPageServedFromZapierCore = isPathServedFromZapierCore(
      window.location.pathname
    );

    const isNotEditorPage = isPublishedPage || isPageServedFromZapierCore;

    setIsNotEditorPage(isNotEditorPage);

    // If it's a project page, we won't fetch the user, so set isLoading to false
    if (isNotEditorPage) {
      setState((previous) => ({ ...previous, isLoading: false }));
    }
  }, [setIsNotEditorPage, setState]);

  const setUser = useCallback((user: ClientUserProfile) => {
    datadogRum.setUser(user);
    setState((previous) => ({
      ...previous,
      user,
      error: undefined,
      isLoading: false,
    }));
    localStorage.setItem("currentAccountId", user.currentAccount.toString());
    localStorage.setItem("customUserId", user.zapierId.toString());
  }, []);

  const setError = useCallback((error: Error) => {
    setState((previous) => ({
      ...previous,
      user: undefined,
      error,
      isLoading: false,
    }));
  }, []);

  const onSuccess = useCallback(
    (data?: { user: ClientUserProfile }) => {
      if (data?.user) {
        setUser(data.user);
      }
    },
    [setUser]
  );

  const onError = useCallback(
    (error: unknown) => {
      if (
        error instanceof TRPCClientError &&
        error.data?.code === "UNAUTHORIZED"
      ) {
        // Redirecting to login will be handled by `withPageAuthRequired` hook.
        setState((previous) => ({
          ...previous,
          user: undefined,
          isLoading: false,
        }));
      } else {
        setError(new Error("Could not fetch current user"));
      }
    },
    [setError]
  );

  // The initial fetch gets our locally cached user so it's quick
  const {
    data: currentUserData,
    error: currentUserError,
    refetch: getUser,
    isPending: initialCurrentUserCallLoading,
  } = trpc.auth.currentUser.useQuery(undefined, {
    enabled: false,
    throwOnError: false,
    meta: { noToast: true },
  });

  const utils = trpc.useUtils();

  useEffect((): void => {
    if (shouldSkipUserFetching) return;
    void getUser();
  }, [getUser, onError, onSuccess, shouldSkipUserFetching]);

  // This call happens in the background but will ensure the local user is
  // refreshed from the monolith.
  const {
    data: syncCurrentUserData,
    error: syncCurrentUserError,
    refetch: syncCurrentUser,
    isFetched: userHasBeenSynced,
    isSuccess,
  } = trpc.auth.syncCurrentUser.useQuery(undefined, {
    enabled: false,
    throwOnError: false,
    meta: { noToast: true },
  });

  useEffect(() => {
    if (isSuccess) {
      void utils.projects.listListingPage.invalidate(undefined, {
        refetchType: "all",
      });
    }
  }, [isSuccess, utils.projects.listListingPage]);

  useEffect((): void => {
    if (
      shouldSkipUserFetching ||
      userHasBeenSynced ||
      initialCurrentUserCallLoading
    )
      return;
    void syncCurrentUser();
  }, [
    syncCurrentUser,
    shouldSkipUserFetching,
    userHasBeenSynced,
    initialCurrentUserCallLoading,
  ]);

  const { user, error, isLoading } = state;

  useEffect(() => {
    onSuccess(currentUserData);
  }, [currentUserData, onSuccess]);

  useEffect(() => {
    onSuccess(syncCurrentUserData);
  }, [syncCurrentUserData, onSuccess]);

  useEffect(() => {
    if (currentUserError) {
      onError(currentUserError);
    }
  }, [currentUserError, onError]);

  useEffect(() => {
    if (syncCurrentUserError) {
      onError(syncCurrentUserError);
    }
  }, [syncCurrentUserError, onError]);

  return (
    <UserContext.Provider
      value={{
        user,
        error,
        isLoading,
        syncSession: async () => {
          await syncCurrentUser();
        },
      }}
    >
      {isLoading && fallback ? fallback : children}
    </UserContext.Provider>
  );
}

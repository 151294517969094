import { css } from "styled-components";

export default css`
  :root {
    --Colors-zapier: #ff4f00;
    --Colors-earth: #201515;
    --Colors-neutral100: #fffdf9;
    --Colors-neutral600: #95928e;
    --Colors-neutral700: #403f3e;
    --Colors-neutral800: #2d2e2e;
    --Shadows-elevation20: #eee 0px 10px 30px 0px;
    --defaultFont: "Inter", Helvetica, arial, sans-serif;
    --paragraph3-fontFamily: var(--defaultFont);
    --paragraph3-fontSize: 16px;
    --paragraph3-lineHeight: 24px;
    --paragraph3-fontWeight: 400;
    --paragraph3Medium-fontWeight: 500;
    --paragraph3Semibold-fontWeight: 600;
    --subHeader1-fontFamily: var(--defaultFont);
    /* Lowered subheader1 font size at Nikki's request, was 24px */
    --subHeader1-fontSize: 18px;
    /* Lowered subheader1 line height to compress modal slightly, was 30px */
    --subHeader1-lineHeight: 24px;
    --subHeader1-fontWeight: 400;
    --subHeader1Semibold-fontWeight: 600;
  }

  .wootric-beacon-parent {
    min-width: 0 !important;
  }

  #wootric-modal {
    font-family: var(--defaultFont);
    border: 0 none transparent;
    width: 50vw !important;
    margin: 0 auto;
    min-width: 600px;
  }

  @media only screen and (max-width: 660px) {
    #wootric-modal {
      display: none !important;
    }
  }

  #wootric-modal .wootric-modal-collapsed {
    display: none !important;
  }

  #wootric-modal > div.wootric-beacon-parent {
    background-color: var(--Colors-neutral100);
    box-shadow: var(--Shadows-elevation20);
  }

  #wootric-modal > div.wootric-beacon-parent #wootric-form {
    font-family: var(--defaultFont) !important;
  }

  #wootric-modal > div.wootric-beacon-parent #wootric-form p,
  #wootric-modal > div.wootric-beacon-parent #wootric-form li,
  #wootric-modal > div.wootric-beacon-parent #wootric-form label {
    font-family: var(--defaultFont) !important;
  }

  #wootric-modal > div.wootric-beacon-parent #wootric-form div.wootric-question,
  #wootric-modal > div.wootric-beacon-parent #wootric-form div.thanks {
    padding-top: 30px;
    padding-bottom: 16px;
  }

  #wootric-modal
    > div.wootric-beacon-parent
    #wootric-form
    div.wootric-question
    p,
  #wootric-modal > div.wootric-beacon-parent #wootric-form div.thanks p {
    font-family: var(--subHeader1-fontFamily);
    font-size: var(--subHeader1-fontSize);
    line-height: var(--subHeader1-lineHeight);
    font-weight: var(--subHeader1Semibold-fontWeight);
  }

  #wootric-modal > div.wootric-beacon-parent #wootric-form #picklist {
    margin-bottom: 13px;
  }

  #wootric-modal > div.wootric-beacon-parent #wootric-form #picklist li {
    border-radius: 18px;
    border: 2px solid var(--Colors-zapier) !important;
    background-color: var(--Colors-neutral100);
    color: var(--Colors-zapier);
    padding: 5px 15px;
    font-family: var(--paragraph3-fontFamily);
    font-size: var(--paragraph3-fontSize);
    line-height: var(--paragraph3-lineHeight);
    font-weight: var(--paragraph3Semibold-fontWeight);
    font-size: 19px;
  }

  #wootric-modal > div.wootric-beacon-parent #wootric-form #picklist li :hover,
  #wootric-modal > div.wootric-beacon-parent #wootric-form #picklist li :focus,
  #wootric-modal
    > div.wootric-beacon-parent
    #wootric-form
    #picklist
    li
    .selected {
    background-color: var(--Colors-zapier) !important;
    color: var(--Colors-neutral100) !important;
    border: none;
    padding: 7px 17px;
  }

  #wootric-modal > div.wootric-beacon-parent #wootric-form #wootric-feedback {
    width: 100%;
    display: flex;
    justify-content: center;
    margin: 0 auto 25px auto;
  }

  #wootric-modal
    > div.wootric-beacon-parent
    #wootric-form
    #wootric-feedback
    textarea {
    border-radius: 3px;
    border-color: var(--Colors-neutral600);
    background-color: var(--Colors-neutral100);
    width: 72%;
    font-family: var(--paragraph3-fontFamily);
    font-size: var(--paragraph3-fontSize);
    line-height: var(--paragraph3-lineHeight);
    font-weight: var(--paragraph3Medium-fontWeight);
  }

  #wootric-modal
    > div.wootric-beacon-parent
    #wootric-form
    #wootric-feedback
    textarea
    ::placeholder,
  #wootric-modal
    > div.wootric-beacon-parent
    #wootric-form
    #wootric-feedback
    textarea
    ::-webkit-input-placeholder,
  #wootric-modal
    > div.wootric-beacon-parent
    #wootric-form
    #wootric-feedback
    textarea
    :-moz-placeholder,
  #wootric-modal
    > div.wootric-beacon-parent
    #wootric-form
    #wootric-feedback
    textarea
    ::-moz-placeholder,
  #wootric-modal
    > div.wootric-beacon-parent
    #wootric-form
    #wootric-feedback
    textarea
    :-ms-input-placeholder {
    color: var(--Colors-earth);
    line-height: 35px !important;
  }

  #wootric-modal
    > div.wootric-beacon-parent
    #wootric-form
    #wootric-feedback
    input {
    border-radius: 3px !important;
    background-color: var(--Colors-earth);
    height: 53px;
    margin-left: 5px !important;
  }

  #wootric-modal
    > div.wootric-beacon-parent
    #wootric-form
    .wootric-alt-anchors {
    display: none;
  }

  #wootric-modal > div.wootric-beacon-parent #wootric-form #wootric-fullscore {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  #wootric-modal
    > div.wootric-beacon-parent
    #wootric-form
    #wootric-fullscore
    ul {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin: 0 15px;
  }

  #wootric-modal
    > div.wootric-beacon-parent
    #wootric-form
    #wootric-fullscore
    ul
    li {
    margin-bottom: 4px;
    border-radius: 3px;
    border-color: var(--Colors-neutral700);
    color: var(--Colors-neutral700);
    font-family: var(--paragraph3-fontFamily);
    font-size: var(--paragraph3-fontSize);
    line-height: var(--paragraph3-lineHeight);
    font-weight: var(--paragraph3Semibold-fontWeight);
    margin-left: 2px;
    margin-right: 2px;
    padding: 8px 15px;
  }

  #wootric-modal
    > div.wootric-beacon-parent
    #wootric-form
    #wootric-fullscore
    ul
    li
    :hover,
  #wootric-modal
    > div.wootric-beacon-parent
    #wootric-form
    #wootric-fullscore
    ul
    li
    :focus,
  #wootric-modal
    > div.wootric-beacon-parent
    #wootric-form
    #wootric-fullscore
    ul
    li
    .selected {
    background: var(--Colors-neutral700);
    color: var(--Colors-neutral100) !important;
  }

  #wootric-modal
    > div.wootric-beacon-parent
    #wootric-form
    #wootric-fullscore
    #wootric-likely-label,
  #wootric-modal
    > div.wootric-beacon-parent
    #wootric-form
    #wootric-fullscore
    #wootric-not-likely-label {
    font-family: var(--paragraph3-fontFamily);
    font-size: var(--paragraph3-fontSize);
    line-height: var(--paragraph3-lineHeight);
    font-weight: var(--paragraph3-fontWeight);
    color: var(--Colors-neutral700);
    font-style: normal;
    padding-bottom: 0 !important;
  }

  #wootric-modal > div.wootric-beacon-parent #wootric-form #wootric-close {
    border: none;
    font-size: 14px;
  }

  #wootric-modal
    > div.wootric-beacon-parent
    #wootric-form
    #wootric-close
    :hover {
    width: 100px;
  }

  #wootric-modal
    > div.wootric-beacon-parent
    #wootric-form
    #wootric-close
    #wootric-x {
    height: 20px;
    width: 20px;
    color: var(--Colors-neutral800) !important;
  }

  #wootric-modal
    > div.wootric-beacon-parent
    #wootric-form
    #wootric-close
    #wootric-dismiss {
    text-transform: capitalize;
  }

  #wootric-modal > div.wootric-beacon-parent .wootric-powered-by {
    visibility: hidden;
  }
`;

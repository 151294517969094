import WootricStyle from "./WootricStyle";
import { createGlobalStyle } from "./styled-components";

const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  * {
    box-sizing: border-box;
  }

  /* Spacing reset */
  h1, h2, h3, h4, h5, h6, p, ul, ol, li, button {
    margin: 0;
  }
  
  /* Button reset */
  button {
    border: none;
    margin: 0;
    padding: 0;
    width: auto;
    overflow: visible;

    background: transparent;

    outline: none;
    &:focus:not(:focus-visible) {
      outline: none;
    }
    &:focus:not(:-moz-focusring) {
      outline: none;
    }


    /* inherit font & color from ancestor */
    color: inherit;
    font: inherit;

    /* Normalize 'line-height'. Cannot be changed from 'normal' in Firefox 4+. */
    line-height: normal;

    /* Corrects font smoothing for webkit */
    -webkit-font-smoothing: inherit;
    -moz-osx-font-smoothing: inherit;

    /* Corrects inability to style clickable 'input' types in iOS */
    -webkit-appearance: none;

    /* Remove excess padding and border in Firefox 4+ */
    &::-moz-focus-inner {
      border: 0;
      padding: 0;
    }
  }
  ${WootricStyle}
`;

export default GlobalStyle;

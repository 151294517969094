import { styled } from "lib/theme";

// Base reusable styles
// -----------------------------------------------------------------------------
// These are meant to be inherited by other typography styles in this file, and
// should not be included in the typography object exported at the bottom of the
// file.

const Base = styled.span`
  font-family: ${({ theme }) => theme.app.fontFamily};
  color: ${({ theme }) => theme.app.colors.text};
  font-size: 16px;
`;

// Generic typography styles
// -----------------------------------------------------------------------------
//  - MUST be added to the exported typography object at the bottom of the file.
//  - MUST not include color, opacity, or any other overly-opinionated styles.
//  - SHOULD inherit from Base or another component from the section above.
//  - CAN be inherited by composite typography styles below.

const paragraph1 = styled(Base)`
  font-size: 18px;
`;

const paragraph1Medium = styled(paragraph1)`
  font-weight: 500;
`;

const paragraph1SemiBold = styled(paragraph1)`
  font-weight: 600;
`;

const paragraph1Bold = styled(Base)`
  font-weight: 700;
`;

const paragraph3 = styled(Base)`
  font-size: 16px;
`;

const paragraph3Medium = styled(paragraph3)`
  font-weight: 500;
`;

const paragraph3Bold = styled(paragraph3)`
  font-weight: 700;
`;

const smallTextMedium = styled(Base)`
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
`;

const smallPrint1 = styled(Base)`
  font-size: 13px;
`;

const smallPrint1SemiBold = styled(smallPrint1)`
  font-weight: 600;
`;

// Context-specific typography styles
// -----------------------------------------------------------------------------
//  - SHOULD inherit from one of the above generic typography styles.
//  - CAN include color, opacity, and anything else.

const modalTitle = styled(Base)`
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
`;

const modalSubtitle = styled(Base)`
  margin-top: 5px;
  font-size: 16px;
  color: ${({ theme }) => theme.app.colors.darkGray};
`;

const modalSubtitleBold = styled(modalSubtitle)`
  font-weight: 700;
`;

// TODO: formLabel
// TODO: formError
// TODO: formHelp

// Typography object
// -----------------------------------------------------------------------------
// These are the styles that you can reference with the `type` property on the
// <Text /> component.

const typography = {
  paragraph1,
  paragraph1Medium,
  paragraph1SemiBold,
  paragraph1Bold,
  paragraph3,
  paragraph3Medium,
  paragraph3Bold,
  smallTextMedium,
  smallPrint1,
  smallPrint1SemiBold,
  modalTitle,
  modalSubtitle,
  modalSubtitleBold,
} as const;

export default typography;

import { TRPCClientError } from "@trpc/client";
import {
  TableUsageLimitExceedAttemptPayload,
  emitTablesUsageLimitsEvent,
} from "observability/tracking/tables";
import {
  isTablesApiError,
  isTooManyFieldsResponse,
  isTooManyRecordsResponse,
  isTooManyTablesResponse,
} from "server/services/tables/types/type-helpers";

export function handleTablesAPIError(
  error: Record<string, any> | Error | undefined
) {
  if (typeof window === "undefined") return;
  const customUserId = parseInt(localStorage.getItem("customUserId") ?? "");
  const accountId = parseInt(localStorage.getItem("currentAccountId") ?? "");

  if (!(error instanceof TRPCClientError) || !isTablesApiError(error.data))
    return;
  const tablesError = error.data;

  const payload: Partial<TableUsageLimitExceedAttemptPayload> = {};
  if (isTooManyTablesResponse(tablesError)) {
    payload.limit_type_name = "Table Limit";
    payload.current_limit_quantity = (tablesError as any).meta.max_tables;
    payload.attempted_overage_quantity = (
      tablesError as any
    ).meta.proposed_tables;
  } else if (isTooManyFieldsResponse(tablesError)) {
    payload.limit_type_name = "Field Limit";
    payload.current_limit_quantity = (tablesError as any).meta.max_fields;
    payload.attempted_overage_quantity = (
      tablesError as any
    ).meta.proposed_fields;
  } else if (isTooManyRecordsResponse(tablesError)) {
    payload.limit_type_name = "Record Limit";
    payload.current_limit_quantity = (tablesError as any).meta.max_records;
    payload.attempted_overage_quantity = (
      tablesError as any
    ).meta.proposed_records;
  }

  if (Object.keys(payload).length > 0) {
    emitTablesUsageLimitsEvent(payload, customUserId, accountId);
  }
}

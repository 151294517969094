export { default as GlobalStyle } from "./GlobalStyle";
/**
 * We do not export `ThemeProvider` from here,
 * because that would make it impossible for us to use `styled` in `ThemeProvider` (circular dependency).
 * Note that the `styled` could be imported _transitively_ by some other component inside `ThemeProvider` which would cause a circular dependency as well.
 */

export {
  createGlobalStyle,
  css,
  keyframes,
  styled,
  useTheme,
} from "./styled-components";

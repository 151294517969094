import { createPortal } from "react-dom";
import { Modal as ZModal } from "@zapier/design-system";
import { ComponentProps } from "react";

type Props = ComponentProps<typeof ZModal>;

/**
 * Simple wrapper for Zinnia's Modal that renders it in a React Portal.
 */
export function Modal(props: Props) {
  const renderModal = () => <ZModal {...props} />;

  if (typeof document !== "undefined") {
    return createPortal(renderModal(), document.body);
  }

  return renderModal();
}

import { config } from "@/config";

export function isPublishedPageHost(host: string | undefined) {
  if (!host) {
    return false;
  }

  const interfacesHost = new URL(config().NEXT_PUBLIC_INTERFACES_BASE_URL).host;
  return host !== interfacesHost;
}

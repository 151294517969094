import { Serialize } from "@trpc/server/unstable-core-do-not-import";
import { paths } from "./generated-schema-v0";
import { has, get } from "lodash";
import { TablesApiError } from "server/services/errors";

export type PickRequestBody<
  Path extends keyof paths,
  Method extends keyof paths[Path],
> = paths[Path][Method] extends {
  requestBody: {
    content: {
      "application/json": infer RequestBody;
    };
  };
}
  ? RequestBody
  : never;

export type PickRequestQuery<
  Path extends keyof paths,
  Method extends keyof paths[Path],
> = paths[Path][Method] extends {
  parameters: {
    query: infer QueryParameters;
  };
}
  ? QueryParameters
  : never;

export type PickResponseBody<
  Path extends keyof paths,
  Method extends keyof paths[Path],
> = paths[Path][Method] extends {
  responses: {
    "200": {
      content: {
        "application/json": infer ResponseBody;
      };
    };
  };
}
  ? ResponseBody
  : never;

export type PickResponses<
  Path extends keyof paths,
  Method extends keyof paths[Path],
> = paths[Path][Method] extends {
  responses: infer Responses;
}
  ? Responses
  : never;

export function isType<T extends Record<string, any>>(
  object: any,
  property: string,
  check?: (value: unknown) => boolean
): object is Serialize<T> {
  if (check) {
    return check(get(object as T, property));
  }
  return has(object as T, property);
}

export function isTablesApiError(
  object: Record<string, any> | Error | undefined
): object is Serialize<TablesApiError> {
  if (typeof object === "undefined") return false;
  return isType<TablesApiError>(
    object,
    "code",
    (code) => typeof code === "string" && code.startsWith("tables:")
  );
}

export function isTooManyFieldsResponse(e: Serialize<TablesApiError>): boolean {
  return isTablesApiError(e) && e.code === "tables:limits:too_many_fields";
}

export function isTooManyTablesResponse(e: Serialize<TablesApiError>): boolean {
  return isTablesApiError(e) && e.code === "tables:limits:too_many_tables";
}

export function isTooManyRecordsResponse(
  e: Serialize<TablesApiError>
): boolean {
  return isTablesApiError(e) && e.code === "tables:limits:too_many_records";
}

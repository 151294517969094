import { isString } from "lodash";
import { config } from "@/config";
import { PROJECT_SESSION_ID_HEADER, PAGE_ID_HEADER } from "../constants";
import {
  getProjectSessionId,
  getProjectSessionPageId,
} from "./project-session-storage";

type Fetch = typeof fetch;

const ALLOWED_PROTOCOLS = ["https:"];
if (
  config().NODE_ENV === "development" ||
  config().NEXT_PUBLIC_APP_ENV === "local"
) {
  ALLOWED_PROTOCOLS.push("http:");
}

Object.freeze(ALLOWED_PROTOCOLS);

/**
 * A wrapper around fetch that adds the Interfaces project session header. This
 * is required to make authenticated requests to our API.
 */
export const fetchWithProjectSession: Fetch = function fetchWithProjectSession(
  ...args
) {
  // Get the request URL
  let url: URL;
  if (isString(args[0])) {
    let urlString = args[0];
    // TODO: fix this hack
    if (urlString[0].match(/^\/(?!\/)/)) {
      urlString = window.location.origin + urlString;
    }
    url = new URL(urlString);
  } else if (args[0] instanceof Request) {
    url = new URL(args[0].url);
  } else {
    url = args[0];
  }

  // Add authorization header only if the request origin is the same as the
  // current origin
  if (
    typeof window !== "undefined" &&
    ALLOWED_PROTOCOLS.includes(url.protocol) &&
    url.origin === window.location.origin
  ) {
    const sessionId = getProjectSessionId();
    const pageId = getProjectSessionPageId();

    if (sessionId || pageId) {
      const requestOptions = args[1] ?? {};

      const existingHeaders = requestOptions?.headers ?? {};
      const newHeaders = new Headers(existingHeaders);

      if (sessionId) {
        newHeaders.set(PROJECT_SESSION_ID_HEADER, sessionId);
      }

      if (pageId) {
        newHeaders.set(PAGE_ID_HEADER, pageId);
      }

      return fetch(args[0], {
        ...requestOptions,
        /**
         * Jest has issues with serializing `Headers`, so we convert it to an object.
         */
        headers: Object.fromEntries(newHeaders.entries()),
      });
    }
  }

  return fetch(...args);
};

import { useTheme } from "lib/theme";
import { Theme } from "lib/theme/themes";
import { ColorName } from "lib/theme/themes/app-theme";
import { ComponentProps, ReactNode } from "react";
import typography from "./typography";

export type TextType = keyof typeof typography;

type Color = ColorName | "currentColor";

export function Text<TAs extends keyof JSX.IntrinsicElements>(
  props: {
    type: TextType;
    as?: TAs;
    color?: Color;
    children: ReactNode;
    "data-testid"?: string;
  } & Omit<ComponentProps<TAs>, "color">
) {
  const { type, color, ...rest } = props;
  const Component = typography[type];

  const theme = useTheme();

  const style = getStyleFromColor(color, theme);

  return (
    <Component style={style} {...rest}>
      {props.children}
    </Component>
  );
}

function getStyleFromColor(
  color: Color | undefined,
  theme: Theme
): React.CSSProperties | undefined {
  if (color === undefined) return undefined;
  if (color === "currentColor") return { color: "currentColor" };
  return { color: theme.app.colors[color] };
}

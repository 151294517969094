// Public pages are accessible either while logged in or while logged out,
// but are served from our base domain, so user sessions should work if logged in.
export const publicPages = ["/pricing"];

export const isPublicPage = (pathname: string) => {
  return publicPages.some((p) => pathname.startsWith(p));
};

// Review SEO note from Matt and incorporate
// Confirm logged in / out states work
// Push PR and send slack message

import { Button as ZButton, ButtonProps, Spinner } from "@zapier/design-system";
import { styled } from "lib/theme";
import { useRouter } from "next/router";
import { ComponentProps, Fragment, MouseEvent, useMemo } from "react";

const SpinnerWrapper = styled.span`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;

type SpinnerColor = ComponentProps<typeof Spinner>["color"];

export type Props = ButtonProps & {
  isLoading?: boolean;
};

export function Button(props: Props) {
  const { isLoading, iconBefore, children, ...rest } = props;

  // Replace children with spinner if isLoading is true
  const renderChildren = () => {
    return (
      <Fragment>
        <span style={{ opacity: isLoading ? 0 : 1 }}>{children}</span>
        {isLoading ? (
          <SpinnerWrapper>
            <Spinner color={getSpinnerColor(props.color)} />
          </SpinnerWrapper>
        ) : null}
      </Fragment>
    );
  };

  const Btn = props.href ? RouterButton : ZButton;

  return (
    <Btn
      {...rest}
      iconBefore={isLoading ? undefined : iconBefore}
      disabled={rest.disabled ?? isLoading}
    >
      {renderChildren()}
    </Btn>
  );
}

function RouterButton(props: Props) {
  const router = useRouter();

  const { isLoading, onClick, iconBefore, children, ...rest } = props;

  // Override the onClick prop so we use nextjs's router for hrefs instead of
  // hard anchor navigation.
  const handleClick = useMemo(() => {
    if (props.href || onClick)
      return (e: MouseEvent<HTMLElement>) => {
        if (props.href && props.target !== "_blank") {
          e.preventDefault();
          void router.push(props.href);
        }

        if (onClick) onClick(e);
      };

    return undefined;
  }, [onClick, props.href, props.target, router]);

  return (
    <ZButton
      {...rest}
      onClick={handleClick}
      iconBefore={isLoading ? undefined : iconBefore}
      disabled={rest.disabled ?? isLoading}
    >
      {children}
    </ZButton>
  );
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function getSpinnerColor(buttonColor: ButtonProps["color"]): SpinnerColor {
  return "dark";

  // NOTE: This switch statement is no longer needed because now we disable the
  // button any time `isLoading` is present, and the disabled state is the same
  // styling regardless of the button color specified. Keeping the code
  // commented here in case we change that in the future.

  // switch (buttonColor) {
  //   case "danger":
  //   case "primary":
  //   case "primary-alt":
  //   case undefined:
  //     return "light";

  //   case "secondary":
  //   case "tertiary":
  //   case "primary-dark":
  //   case "secondary-dark":
  //     return "dark";

  //   default:
  //     const _exhaustiveCheck: never = buttonColor;
  //     return _exhaustiveCheck;
  // }
}

import {
  ModalContent,
  ModalContentBody,
  ModalContentFooter,
  ModalContentHeader,
} from "@zapier/design-system";
import { Button } from "components/Button";
import { Text } from "block-system/components/Text";
import { Modal } from "components/Modal";
import { useSyncExternalStore } from "react";
import { trpc } from "utils/trpc";

/**
 * This has to be a singleton.
 *
 * We want to show the modal once, but the `showModal` might be called multiple times,
 * since multiple queries might fail with a session expired error.
 */
const SessionExpiredModalState = {
  visible: false,
  subscribers: new Set<(visible: boolean) => void>(),

  subscribe(listener: (visible: boolean) => void) {
    this.subscribers.add(listener);
    return () => {
      this.subscribers.delete(listener);
    };
  },

  showModal() {
    if (this.visible) {
      return;
    }

    this.visible = true;
    this.subscribers.forEach((listener) => listener(this.visible));
  },

  closeModal() {
    if (!this.visible) {
      return;
    }

    this.visible = false;
    this.subscribers.forEach((listener) => listener(this.visible));
  },
};

export function ProjectSessionExpiredModal() {
  const utils = trpc.useUtils();

  const logout = async () => {
    try {
      await utils.projectAuth.logout.fetch();
    } catch (error) {
      console.error("Failed to logout:", error);
    } finally {
      /**
       * Doing window.location.reload will re-initialize the Singleton of SessionExpiredModalState.
       *
       * We do not want to call `closeModal` here, because the modal might close BEFORE we navigate to the login page.
       * That would be suboptimal, as the user might be left starting at a default error page.
       *
       * We want to force the browser to reload the page to ensure all the state is reset.
       *
       * We are not using `window.location.href = "/"` because we observed the page sometimes wont reload if
       * the user is already on the "/" path.
       */
      window.location.reload();
    }
  };

  const modalVisible = useSyncExternalStore(
    (subscriber) => SessionExpiredModalState.subscribe(subscriber),
    () => SessionExpiredModalState.visible,
    () => SessionExpiredModalState.visible
  );

  if (!modalVisible) {
    return null;
  }

  const modalTitle = "Your session has expired";

  return (
    <Modal
      onClosed={() => {}}
      canClose={false}
      canScrollBody={false}
      ariaLabel={modalTitle}
    >
      <ModalContent>
        <ModalContentHeader align={"center"}>
          <Text type="modalTitle">{modalTitle}</Text>
        </ModalContentHeader>
        <ModalContentBody align={"center"}>
          <Text type="modalSubtitle">
            Login again to access this interface.
          </Text>
        </ModalContentBody>
        <ModalContentFooter>
          <Button
            type="button"
            onClick={() => {
              void logout();
            }}
          >
            Log in
          </Button>
        </ModalContentFooter>
      </ModalContent>
    </Modal>
  );
}

ProjectSessionExpiredModal.open = () => SessionExpiredModalState.showModal();
ProjectSessionExpiredModal.close = () => SessionExpiredModalState.closeModal();

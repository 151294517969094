// NOTE We need to wrap localStorage in a try/catch because it can throw in
// embedded contexts (e.g. in an iframe) due to cross-origin restrictions.

const localStorageSessionKey = "interfacesSessionId";

const _sessionIds: Record<string, string | undefined> = {};

const _pageIds: Record<string, string | undefined> = {};

const FALLBACK_INSTANCE_ID = "default";

_sessionIds[FALLBACK_INSTANCE_ID] = undefined;

export function setProjectSession({
  sessionId,
  pageId,
}: {
  sessionId: string;
  pageId: string | undefined;
}) {
  if (typeof window !== "undefined") {
    const { id, key } = getInstance(window);

    _sessionIds[id] = sessionId;
    _pageIds[id] = pageId;

    try {
      window.localStorage.setItem(key, sessionId);
    } catch (err) {
      console.error(err);
    }
  }
}

export function getProjectSessionId() {
  if (typeof window !== "undefined") {
    try {
      const { id, key } = getInstance(window);

      return _sessionIds[id] || window.localStorage.getItem(key);
    } catch {
      return _sessionIds[FALLBACK_INSTANCE_ID];
    }
  }
}

export function getProjectSessionPageId() {
  if (typeof window !== "undefined") {
    const { id } = getInstance(window);
    return _pageIds[id];
  }
}

// Workaround for embedding multiple pages of same interface project on the same host webpage
function getInstance(win: Window) {
  // check the pathname for the current window
  const pathname = win.location.pathname;
  // if the pathname doesn't start with /_z/embed/, then we're not in an
  // embedded context, so we can use the default instance id
  if (!pathname.startsWith("/_z/embed/")) {
    return { id: FALLBACK_INSTANCE_ID, key: localStorageSessionKey };
  }

  /**
    otherwise, we're in an embedded context, so we need to parse the pathname

    pathname examples:
      /_z/embed/chatbot/clldnflu0009785rvqhko7nme/clo5smuqc00032tnvmdwc7ufg
      /_z/embed/page/clldnflu0009785rvqhko7nme

    the pathname will have at least 5 parts, possibly 6:
    ['', '_z', 'embed', 'page|chatbot', {pageId}, {chatbotId}]
  */
  const [, , , , pageId, chatbotId] = pathname.split("/");

  const id = chatbotId ? `${pageId}/${chatbotId}` : pageId;
  const key = `${localStorageSessionKey}-${id}`;

  return { id, key };
}

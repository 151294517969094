import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
} from "react";
import { useRouter } from "next/router";
import {
  RemoveAutoPopulatedEventFields,
  WebAnalyticsTrackingUserInteractionUserInteractionEvent,
  WebAnalyticsTrackingUserInteractionUserInteractionEventName,
  PlatformInterfacesWebAnalyticsInterfaceBuilderInteractionEvent,
  PlatformInterfacesWebAnalyticsInterfaceConsumerInteractionEvent,
  PlatformInterfacesWebAnalyticsInterfaceChatbotAITokenUsageReceivedEvent,
  PlatformChatbotsWebAnalyticsChatbotsConsumerInteractionEvent,
  AiTrackingPredictionUserFeedbackEvent,
} from "@zapier/avro-events";

import { emit } from "./emit";
import { isDatadog } from "../../utils/isDatadog";
import { useUser } from "lib/context/user-context";
import {
  BUILDER_INTERACTION_EVENT,
  CONSUMER_INTERACTION_EVENT,
  CHATBOTS_CONSUMER_INTERACTION_EVENT,
  CHATBOT_INTERACTION_EVENT,
  PAGE_VIEW_EVENT,
  SYSTEM_NAME,
  USER_INTERACTION_EVENT,
} from "./events";

type RemoveConstants<T> = Omit<
  RemoveAutoPopulatedEventFields<T>,
  "system_name" | "customuser_id" | "account_id" | "visitor_id" | "session_id"
>;

type Props = {
  children: ReactNode;
  skipPageViewEvent?: boolean;
};

type UserInteractionEventPayload =
  RemoveConstants<WebAnalyticsTrackingUserInteractionUserInteractionEvent.EventFields>;

type BuilderInteractionEventPayload =
  RemoveConstants<PlatformInterfacesWebAnalyticsInterfaceBuilderInteractionEvent.EventFields> & {
    event_action: "click";
  };
type ConsumerInteractionEventPayload =
  RemoveConstants<PlatformInterfacesWebAnalyticsInterfaceConsumerInteractionEvent.EventFields> & {
    embedded_flag?: boolean;
  };
type ChatbotsConsumerInteractionEventPayload =
  RemoveConstants<PlatformChatbotsWebAnalyticsChatbotsConsumerInteractionEvent.EventFields> & {
    embedded_flag?: boolean;
  };
type ChatbotAITokenUsageReceivedEventPayload =
  RemoveConstants<PlatformInterfacesWebAnalyticsInterfaceChatbotAITokenUsageReceivedEvent.EventFields>;

type AiTrackingPredictionUserFeedbackEventPayload =
  RemoveConstants<AiTrackingPredictionUserFeedbackEvent.EventFields>;

export type TrackingContextProps = {
  emitUserInteractionEvent: (payload: UserInteractionEventPayload) => void;
  emitAiTrackingPredictionUserFeedbackEvent: (
    payload: AiTrackingPredictionUserFeedbackEventPayload
  ) => void;
  emitBuilderInteractionEvent: (
    payload: BuilderInteractionEventPayload
  ) => void;
  emitConsumerInteractionEvent: (
    payload: ConsumerInteractionEventPayload
  ) => void;
  emitChatbotsConsumerInteractionEvent: (
    payload: ChatbotsConsumerInteractionEventPayload
  ) => void;
  emitChatbotAITokenUsageReceivedEvent: (
    payload: ChatbotAITokenUsageReceivedEventPayload
  ) => void;
};

const defaultValue: TrackingContextProps = {
  emitAiTrackingPredictionUserFeedbackEvent: () => console.log,
  emitBuilderInteractionEvent: () => console.log,
  emitConsumerInteractionEvent: () => console.log,
  emitChatbotsConsumerInteractionEvent: () => console.log,
  emitChatbotAITokenUsageReceivedEvent: () => console.log,
  emitUserInteractionEvent: () => console.log,
};

const TrackingContext = createContext(defaultValue);
export function useTrackingContext() {
  return useContext(TrackingContext);
}

export function TrackingProvider({
  children,
  skipPageViewEvent = false,
}: Props) {
  const { asPath: path } = useRouter();
  const { user, isLoading } = useUser();
  const accountId = user?.currentAccount;

  const applyConstants = useCallback(
    (overrides?: Record<string, unknown>) => ({
      system_name: SYSTEM_NAME,
      customuser_id: user?.zapierId ?? null,
      account_id: accountId,
      ...overrides,
    }),
    [accountId, user?.zapierId]
  );

  const trackingFunctions = useMemo(() => {
    const emitUserInteractionEvent = (payload: UserInteractionEventPayload) => {
      emit(
        {
          ...payload,
          ...applyConstants(),
        },
        USER_INTERACTION_EVENT
      );
    };

    const emitBuilderInteractionEvent = (
      payload: BuilderInteractionEventPayload
    ) => {
      emit(
        {
          ...payload,
          ...applyConstants(),
        },
        BUILDER_INTERACTION_EVENT
      );
    };

    const emitConsumerInteractionEvent = (
      payload: ConsumerInteractionEventPayload
    ) => {
      emit(
        {
          ...payload,
          ...applyConstants(),
        },
        CONSUMER_INTERACTION_EVENT
      );
    };

    const emitChatbotsConsumerInteractionEvent = (
      payload: ChatbotsConsumerInteractionEventPayload
    ) => {
      emit(
        {
          ...payload,
          ...applyConstants({
            system_name: "chatbots",
          }),
        },
        CHATBOTS_CONSUMER_INTERACTION_EVENT
      );
    };

    const emitChatbotAITokenUsageReceivedEvent = (
      payload: ChatbotAITokenUsageReceivedEventPayload
    ) => {
      emit(
        {
          ...payload,
          ...applyConstants(),
        },
        CHATBOT_INTERACTION_EVENT
      );
    };

    const emitAiTrackingPredictionUserFeedbackEvent = (
      payload: AiTrackingPredictionUserFeedbackEventPayload
    ) => {
      emit(
        {
          ...payload,
          ...applyConstants(),
        },
        "ai.tracking.PredictionUserFeedbackEvent"
      );
    };

    return {
      emitAiTrackingPredictionUserFeedbackEvent,
      emitBuilderInteractionEvent,
      emitConsumerInteractionEvent,
      emitChatbotsConsumerInteractionEvent,
      emitChatbotAITokenUsageReceivedEvent,
      emitUserInteractionEvent,
    };
  }, [applyConstants]);

  useEffect(() => {
    // Only track on the client side (on non-published pages)
    // and do not track the pre-hydrated path that doesn't include the
    // real params (i.e. [accountId])
    if (
      !skipPageViewEvent &&
      typeof window !== "undefined" &&
      !(path.includes("[") && path.includes("]")) &&
      !isDatadog() &&
      !isLoading
    ) {
      // We only need to specify the system name that should be used for the PageViewEvent
      // The other PageViewEvent fields are set by automatically by events-toolkit-browser and the v4 Events API
      // https://engineering.zapier.com/guides/event-streaming/tracking-events/
      emit(
        {
          system_name: "Interfaces",
          customuser_id: user?.zapierId ?? null,
          account_id: accountId,
        },
        PAGE_VIEW_EVENT
      );
    }
  }, [path, accountId, user?.zapierId, isLoading, skipPageViewEvent]);

  return (
    <TrackingContext.Provider value={trackingFunctions}>
      {children}
    </TrackingContext.Provider>
  );
}

export const PROJECT_SESSION_ID_HEADER = "x-interfaces-session";
export const PROJECT_SESSION_ID_COOKIE = "projectSessionId";

export const PAGE_ID_HEADER = "x-interfaces-page-id";

export const SERVICE_NAME = "interfaces";
export const SERVICE_NAME_API = `${SERVICE_NAME}-api`;

// Help doc URLs
export const PAGE_EMBED_HELP_DOC_URL =
  "https://help.zapier.com/hc/en-us/articles/14490267815949-Create-interactive-pages-and-apps-with-Zapier-Interfaces-Beta-#share-and-embed-pages-0-5";

export const CHAT_EMBED_HELP_DOC_URL =
  "https://help.zapier.com/hc/en-us/articles/18151892318861-Embed-a-chatbot-on-a-webpage";

// `staleTime` value set on queries that are hydrated via SSR. The value of
// 5000ms is a little arbitrary. The idea is just to prevent the client from
// fetching when it first initializes, provided that data was pre-loaded from
// the server.
export const SSR_STALE_TIME = 5000;
